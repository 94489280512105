/*----------------------------------------------------------------------------------------------------------------------
Paths
----------------------------------------------------------------------------------------------------------------------*/

$path_fonts: '../assets/fonts';

/*----------------------------------------------------------------------------------------------------------------------
  Fonts
----------------------------------------------------------------------------------------------------------------------*/

// SpaceGrotesk

$SpaceGrotesk: ('SpaceGrotesk');

//$SpaceGrotesk-Thin: "SpaceGrotesk-Thin";
//$SpaceGrotesk-Thin-Italic: "SpaceGrotesk-ThinItalic";
//
$SpaceGrotesk-Light: 'SpaceGrotesk-Light';
//$SpaceGrotesk-Light-Italic: "SpaceGrotesk-LightItalic";

$SpaceGrotesk-Medium: 'SpaceGrotesk-Medium';
//$SpaceGrotesk-Medium-Italic: "SpaceGrotesk-MediumItalic";

$SpaceGrotesk-Regular: 'SpaceGrotesk-Regular';
$SpaceGrotesk-SemiBold: 'SpaceGrotesk-SemiBold';

$SpaceGrotesk-Bold: 'SpaceGrotesk-Bold';
//$SpaceGrotesk-Bold-Italic: "SpaceGrotesk-BoldItalic";

$SpaceGrotesk-Black: 'SpaceGrotesk-Black';
//$SpaceGrotesk-Black-Italic: "SpaceGrotesk-BlackItalic";

// Montserrat

$Montserrat: ('Montserrat');

//$Montserrat-Thin: "Montserrat-Thin";
//$Montserrat-Thin-Italic: "Montserrat-ThinItalic";
//
$Montserrat-Light: 'Montserrat-Light';
//$Montserrat-Light-Italic: "Montserrat-LightItalic";

$Montserrat-Medium: 'Montserrat-Medium';
//$Montserrat-Medium-Italic: "Montserrat-MediumItalic";

$Montserrat-Regular: 'Montserrat-Regular';
$Montserrat-SemiBold: 'Montserrat-SemiBold';

$Montserrat-Bold: 'Montserrat-Bold';
//$Montserrat-Bold-Italic: "Montserrat-BoldItalic";

$Montserrat-Black: 'Montserrat-Black';
//$Montserrat-Black-Italic: "Montserrat-BlackItalic";

$BebasNeue: ('BebasNeue');
$BebasNeue-Regular: 'BebasNeue-Regular';

$fonts_spacegrotesk: (
  $SpaceGrotesk-Light,
  $SpaceGrotesk-Medium,
  $SpaceGrotesk-Regular,
  $SpaceGrotesk-SemiBold,
  $SpaceGrotesk-Bold
);

$fonts_montserrat: (
  $Montserrat-Light,
  $Montserrat-Medium,
  $Montserrat-Regular,
  $Montserrat-SemiBold,
  $Montserrat-Bold
);

$fonts_bebasneue: ($BebasNeue-Regular);

@each $font in $fonts_spacegrotesk {
  @font-face {
    font-family: $font;
    src: url('#{$path_fonts}/#{$SpaceGrotesk}/#{$font}.ttf') format('truetype');
    //src: url('#{$path_fonts}/#{$font}/#{$font}.eot?#iefix') format('embedded-opentype'),
    //url('#{$path_fonts}/#{$font}/#{$font}.woff') format('woff'),
    //url('#{$path_fonts}/#{$font}/#{$font}.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}

@each $font in $fonts_montserrat {
  @font-face {
    font-family: $font;
    src: url('#{$path_fonts}/#{$Montserrat}/#{$font}.ttf') format('truetype');
    //src: url('#{$path_fonts}/#{$font}/#{$font}.eot?#iefix') format('embedded-opentype'),
    //url('#{$path_fonts}/#{$font}/#{$font}.woff') format('woff'),
    //url('#{$path_fonts}/#{$font}/#{$font}.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}

@each $font in $fonts_bebasneue {
  @font-face {
    font-family: $font;
    src: url('#{$path_fonts}/#{$BebasNeue}/#{$font}.ttf') format('truetype');
    //src: url('#{$path_fonts}/#{$font}/#{$font}.eot?#iefix') format('embedded-opentype'),
    //url('#{$path_fonts}/#{$font}/#{$font}.woff') format('woff'),
    //url('#{$path_fonts}/#{$font}/#{$font}.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}
