@import 'reset';
@import 'variables';
@import 'fonts';

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Space Grotesk', 'BebasNeue', 'Montserrat', sans-serif;
}
.MuiContainer-fixed {
  max-width: 1440px !important;
}

@media screen and (min-width: 960px) {
  html {
    margin-right: calc(-100vw + 100%);
    overflow-x: hidden;
  }
}
